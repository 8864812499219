/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "variables.less";
@import "mixins.less";

// Reset and dependencies
@import "normalize.less";
@import "print.less";
//@import "glyphicons.less";

// Core CSS
@import "scaffolding.less";
@import "type.less";
@import "code.less";
@import "grid.less";
@import "tables.less";
@import "forms.less";
@import "buttons.less";

// Components
@import "component-animations.less";
//@import "button-groups.less";
//@import "input-groups.less";
@import "navs.less";
@import "navbar.less";
//@import "breadcrumbs.less";
//@import "pagination.less";
//@import "pager.less";
//@import "labels.less";
//@import "badges.less";
//@import "jumbotron.less";
//@import "thumbnails.less";
//@import "alerts.less";
//@import "progress-bars.less";
//@import "media.less";
//@import "list-group.less";
//@import "panels.less";
//@import "responsive-embed.less";
//@import "wells.less";
//@import "close.less";

// Components w/ JavaScript
//@import "modals.less";
//@import "tooltip.less";
//@import "popovers.less";
//@import "carousel.less";

// Utility classes
@import "utilities.less";
@import "responsive-utilities.less";

body {
	background: #000;
    font-family: 'Roboto Slab', serif;
}

.navbar {
	padding: 20px 4em;
	border: none;
	background: rgba(0,0,0,0.9);
    letter-spacing: 0.08em;

    @media (min-width: 1441px) {
    	padding: 20px 8em;
    }

    .lang {
    	width: 22x;
    	height: 22px;
    }	
}

.navbar-brand {
	
	margin: 0;
	padding: 0;

	img {
		height: 60px;
		margin-top: -6px;
	}

}

#navbar {
	font-size: 1.2em;
}

.hero-unit {
	
	background-image: url(../../images/tkp_hero.jpg);
	background-size: cover;
	background-position: left top;
	padding-top: 10em;
	padding-left: 2em;
	padding-right: 2em;
	min-height: 60vh;

	@media (min-width: 1024px) {
			padding-left: 10em;
			padding-right: 10em;
	}

	@media (min-width: 1024px) {
    	display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 100vh;
    }	
	
	.title {
		width: 50%;
		@media (min-width: 1024px) {
			width: 50%;
		}
	}

	.text {
		width: 100%;
		color: #fff;
		@media (min-width: 1024px) {
			width: 50%;
		}


	}

}

.grid-content {

	
	&.chapter-1 {
	position: relative;
	background-image: url(../../images/home_page_1.jpg);
	background-size: cover;
	background-position: right center;
	background-repeat: no-repeat;
	}

	&.chapter-2 {
	position: relative;
	background-image: url(../../images/home_landing.jpg);
	background-size: cover;
	background-position: right center;
	background-repeat: no-repeat;
	}

	&.chapter-3 {
	position: relative;
	background-image: url(../../images/home_page_2.jpg);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	}

	&.chapter-4 {
	position: relative;
	background-image: url(../../images/home_page_4.jpg);
	background-size: cover;
	background-position: right center;
	background-repeat: no-repeat;
	}
	
	.block {
        background: #000; //#131827;
        padding: 2em;
        color: #fff;
        background: rgba(0,0,0,0.9);	
        display: grid;
        align-items: center;

        @media (min-width: @screen-lg-min) {
        	padding: 8em;
        	min-height: 768px;
        }

	}

	p {
		
		padding-bottom: 1em;
		line-height: 1.74em;
		font-weight: 300;

		@media (min-width: @screen-lg-min) {
        	font-size: 120%;
        }

	}

	h1, h3{
		color: #fff;
		margin-bottom: 1.15em;
		position: relative;
		letter-spacing:0.08em;
	}

	h1 {
		font-size: 2.15em;
		text-transform: uppercase;
	}

	h3 {
		margin-bottom: 0.685em;
		font-size: 1.35em;
		font-weight: 300;
	}

	h1:before {
		position: absolute;
		width: 10%;
		height: 12px;
		background: #2C437E;
		left: -2.5%;
		content: '';
		top: -1em;
	}

}

.row.content:nth-child(even) .block{
	
    //margin-left: -5vh;
}


.row.content:nth-child(odd) .block{
	
 	//margin-right: -5vh;

}

.image-grid {
  //background: lighten(#000, 10%);	
  -webkit-column-count: 5;
  -webkit-column-gap:   10px;
  -moz-column-count:    5;
  -moz-column-gap:      10px;
  column-count:         5;
  column-gap:           10px;
  padding-top: 10px;

  img {
  	width: 100%; 
  	height: auto;
  	filter: grayscale(100%);
  	transition: all 0.4s;
  	margin-bottom: 10px;
  	&:hover {
  		filter: grayscale(0%);
  	}
  }  

}

footer{
	
	color: #fff;
	padding: 2em;

}

span.hilite{
  background: #2C437E;
  padding: 2px;
}

